<template>
  <v-card :loading class="overflow-auto">
    <v-card-title>Task step</v-card-title>

    <v-card-text class="overflow-scroll">
      <v-alert v-if="errorMessage" :text="errorMessage" type="error" />

      <hr />

      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-bind="completeByAttrs"
            :error-messages="errors?.step_complete_by"
            label="Step complete by"
            clearable
            v-on="completeByListeners"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="task.action_date"
            :error-messages="errors?.action_date"
            type="date"
            label="Date"
          />
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="task.description"
            :error-messages="errors?.description"
            label="Description"
          />
        </v-col>
      </v-row>

      <component
        :is="step"
        v-if="task.step"
        v-model="extraFields"
        :task="task"
        :errors="errors"
      />

      <hr />

      <v-row v-if="task?.next_step">
        <v-col cols="12" class="text-center">
          <div class="h4">Next step</div>
          <div>{{ task.next_step.name }}</div>
        </v-col>

        <v-col cols="12">
          <hr />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-bind="nextStepForAttrs"
            :error-messages="errors?.next_step_for"
            label="Next step for"
            clearable
            v-on="nextStepForListeners"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="green" block :loading :disabled @click="submitTask">
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ImpoundCallTowingStep from '@/components/form/storage/impound/CallTowingStep.vue';
import ImpoundExecutionStep from '@/components/form/storage/impound/ExecutionStep.vue';
import ImpoundProcessStep from '@/components/form/storage/impound/ProcessStep.vue';
import ImpoundStorageStep from '@/components/form/storage/impound/StorageStep.vue';
import ImpoundServicesStep from '@/components/form/storage/impound/ServicesStep.vue';
import ImpoundInspectionStep from '@/components/form/storage/impound/InspectionStep.vue';
import ReprimandExecutionStep from '@/components/form/reprimand/ExecutionStep.vue';

import { useEmployeeAutocomplete } from '@/composables/autocomplete/employee-autocomplete';
import { useErrorsForm } from '@/composables/form/errorsForm.js';
import { getTask, completeStep } from '@/services/api/task.js';
import { reactive, computed, ref } from 'vue';

let { taskId, section } = defineProps({
  taskId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

const task = reactive({});
const extraFields = reactive({});
const loading = ref(false);
const disabled = ref(false);

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

/**
 * @returns {import('vue').Component|null}
 */
function getImpoundStepComponent() {
  switch (task.step) {
    case 'CallTowingStep':
      return ImpoundCallTowingStep;
    case 'ExecutionStep':
      return ImpoundExecutionStep;
    case 'ProcessStep':
      return ImpoundProcessStep;
    case 'StorageStep':
      return ImpoundStorageStep;
    case 'ServicesStep':
      return ImpoundServicesStep;
    case 'InspectionStep':
      return ImpoundInspectionStep;
    default:
      return null;
  }
}

/**
 * @returns {import('vue').Component|null}
 */
function getReprimandStepComponent() {
  switch (task.step) {
    case 'ExecutionStep':
      return ReprimandExecutionStep;
    default:
      return null;
  }
}

const step = computed(() => {
  switch (section) {
    case 'impound':
      return getImpoundStepComponent();
    case 'reprimand':
      return getReprimandStepComponent();
    default:
      return null;
  }
});

const emits = defineEmits(['task-completed']);

const {
  attrs: nextStepForAttrs,
  listeners: nextStepForListeners,
  setPreselectedEmployee: setPreselectedNextStepFor,
} = useEmployeeAutocomplete({ minSearchLength: 1 });

const {
  attrs: completeByAttrs,
  listeners: completeByListeners,
  setPreselectedEmployee: setPreselectedStepCompletBy,
} = useEmployeeAutocomplete({ minSearchLength: 1 });

function fetchTask() {
  loading.value = true;

  getTask(taskId)
    .then((t) => {
      Object.assign(task, t);
      task.action_date =
        task.action_date || new Date().toISOString().split('T')[0];
      setPreselectedStepCompletBy(task.task_for_id);
      task.next_step?.taskFor &&
        setPreselectedNextStepFor(task.next_step.taskFor.id);
    })
    .finally(() => {
      loading.value = false;
    });
}

function submitTask() {
  clearErrors();
  loading.value = true;

  let payload = {
    step_complete_by: completeByAttrs.modelValue,
    next_step_for: nextStepForAttrs.modelValue,
    description: task.description,
    action_date: task.action_date,
    ...extraFields,
  };

  completeStep(task.id, payload)
    .then(() => {
      emits('task-completed');
      disabled.value = true;
    })
    .catch((e) => {
      if (setErrorsFromResponse(e)) {
        return;
      }

      throw e;
    })
    .finally(() => {
      loading.value = false;
    });
}

fetchTask();
</script>
