<template>
  <DeleteButton
    v-if="deleteUrl"
    v-model="deleteModal"
    :url="deleteUrl"
    no-button
    @deleted="fetchCosts"
  />

  <v-table>
    <thead>
      <tr>
        <th>Description</th>
        <th class="text-right">Amount</th>
        <th v-can:any="optionsPermissions" class="text-right d-print-none">
          Options
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="cost in costs" :key="cost.id">
        <td>
          <span>{{ cost.service.name }}</span>
          <v-icon
            v-if="cost.note"
            v-tooltip="cost.note"
            color="grey"
            class="ml-1"
            icon="mdi-message-text"
          />
        </td>

        <td class="text-right">
          {{ formatMoney(cost.price) }}
        </td>

        <td v-can:any="optionsPermissions" class="text-right d-print-none">
          <v-menu>
            <template #activator="{ props: aProps }">
              <v-btn icon="mdi-dots-vertical" v-bind="aProps" variant="text" />
            </template>

            <v-list>
              <v-list-item v-can="section + '.cost.update'">
                <v-btn
                  :data-cost-id="cost.id"
                  data-ajax-type="edit"
                  variant="text"
                  color="blue"
                  text="Edit"
                  block
                  v-bind="editBtnProps"
                />
              </v-list-item>

              <v-list-item v-can="section + '.cost.delete'">
                <v-btn
                  color="red"
                  variant="text"
                  text="Delete"
                  block
                  @click="deleteCost(cost.id)"
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="bg-grey-lighten-1 font-weight-bold">
        <td>Subtotal</td>
        <td class="text-right">{{ formatMoney(totalPrice) }}</td>
        <td
          v-can:any="'reprimand.summary.update|reprimand.summary.delete'"
        ></td>
      </tr>
    </tfoot>
  </v-table>
</template>

<script setup>
import DeleteButton from '@/components/general/DeleteButton.vue';

import { computed, onMounted, ref, watch } from 'vue';
import { formatMoney } from '@/utils/formatter/number.js';
import axios from '@/plugins/axios.js';

const itemsCounter = defineModel('itemsCounter', {
  type: Number,
  default: 0,
});

const { section, sectionId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

const costs = ref([]);
const selectedCost = ref(null);
const deleteModal = ref(false);

watch(() => sectionId, fetchCosts);

const optionsPermissions = computed(() => {
  return section + '.cost.update|' + section + '.cost.delete';
});

const totalPrice = computed(() =>
  costs.value.reduce((acc, cost) => acc + cost.price, 0)
);

const deleteUrl = computed(() => {
  if (!selectedCost.value || !sectionId) {
    return null;
  }

  return route('api.v1.' + section + 's.costs.destroy', [
    sectionId,
    selectedCost.value?.id,
  ]);
});

function fetchCosts() {
  if (!sectionId) {
    setTimeout(() => {
      if (!sectionId) {
        console.error('Section ID is not set');
      }
    }, 1000);

    return;
  }

  axios
    .get(route('api.v1.' + section + 's.costs.index', [sectionId]))
    .then(({ data }) => {
      costs.value = data.data;
      itemsCounter.value = costs.value.length;
    });
}

/**
 * @param {number} costId
 */
function deleteCost(costId) {
  selectedCost.value = costs.value.find((cost) => cost.id === costId);

  console.log(selectedCost.value);
  if (selectedCost.value) {
    deleteModal.value = true;
  }
}

// bridge for legacy code
const editBtnProps = computed(() => {
  switch (section) {
    case 'storage':
      return {
        'data-target': 'btn-storage-cost',
      };

    default:
      return {};
  }
});

onMounted(fetchCosts);
</script>
