/**
 * @type {{[key: string]: Intl.DateTimeFormatOptions}} options
 */
const options = {
  datetime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  date: {
    year: 'numeric',
    month: '',
    day: 'numeric',
  },
  time: {
    hour: 'numeric',
    minute: 'numeric',
  },
  fullDate: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  fullDateTime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  shortDate: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  shortDateTime: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
};

/**
 * @param {(Date|string|null)} date
 * @param {('datetime'|'date'|'time'|'fullDate'|'fullDateTime'|'shortDate'|'shortDateTime')} [stringFormat]
 * @param {string} [tz]
 * @returns {string|null}
 */
export const format = (
  date,
  stringFormat = 'datetime',
  tz = 'America/Aruba'
) => {
  if (!date) {
    return null;
  }

  const defaultOptions = options[stringFormat];

  if (!defaultOptions) {
    console.error(`Option ${stringFormat} is not supported`);
    return null;
  }

  date = new Date(date);
  defaultOptions.timeZone = tz;

  return date.toLocaleString('en-US', defaultOptions);
};

/**
 * @param {string} time
 * @returns {string}
 */
export const formatTime = (time) => {
  let [hours, minutes] = time.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (isNaN(hours) || isNaN(minutes)) {
    return '';
  }

  let _hours = hours > 12 ? hours - 12 : hours;
  _hours = _hours === 0 ? 12 : _hours;

  return `${_hours}:${minutes.toString().padStart(2, '0')} ${
    hours >= 12 ? 'PM' : 'AM'
  }`;
};
