<template>
  <v-table>
    <thead>
      <tr>
        <th>Invoice number</th>
        <th class="text-right">Balance</th>
        <th class="text-right">Total</th>
        <th>Description</th>
        <th class="text-right">Date created</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="invoice in invoices" :key="invoice.id">
        <td>
          <a
            :href="'/invoice/' + invoice.id"
            class="font-weight-bold text-reset"
          >
            {{ invoice.invoice_number }}
          </a>
        </td>

        <td class="text-right">
          <span>{{ formatMoney(invoice.balance) }}</span>
        </td>

        <td class="text-right">
          <span>{{ formatMoney(invoice.total) }}</span>
        </td>

        <td>
          <template v-for="item in invoice.items" :key="item.id">
            <div v-if="item.from">
              {{ formatDate(item.from, 'shortDate') }} -
              {{ formatDate(item.to, 'shortDate') }}
            </div>

            <div v-for="cost in item.items" :key="cost.id">
              {{ cost.service.name }} - {{ formatMoney(cost.price) }}
            </div>
          </template>
        </td>

        <td class="text-right">
          {{ formatDate(invoice.created_at, 'shortDate') }}
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { format as formatDate } from '@/utils/formatter/date.js';
import { formatMoney } from '@/utils/formatter/number.js';
import axios from '@/plugins/axios.js';

const invoiceCounter = defineModel('itemsCounter', {
  type: Number,
  default: 0,
});

const { section, sectionId } = defineProps({
  sectionId: {
    type: Number,
    required: false,
    default: null,
  },

  section: {
    type: String,
    required: false,
    default: null,
  },
});

const invoices = ref([]);

watch(() => sectionId, fetchInvoices);

function fetchInvoices() {
  if (!sectionId) {
    setTimeout(() => {
      if (!sectionId) {
        console.error('Section ID is not set');
      }
    }, 1000);

    return;
  }

  axios
    .get(route('api.v1.' + section + 's.invoices.index', [sectionId]))
    .then(({ data }) => {
      invoices.value = data.data;
      invoiceCounter.value = invoices.value.length;
    });
}

onMounted(() => {
  fetchInvoices();
});
</script>
